
import { defineComponent } from 'vue';

/**
 * Simple component which provides a mask that blurs the background based on the vuex store variable
 * 'showDialogMask'
 */
export default defineComponent({
    name: 'DialogMask',
    computed: {
        /**
         * Determines whether the dialog mask should be shown or not
         */
        showDialogMask(): boolean {
            return this.$store.state.showDialogMask;
        },
    },
});
