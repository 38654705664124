import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "chip-text no-select" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["chip", { 'chip-suggestion': _ctx.isSuggestion, 'box-shadow': _ctx.hasShadow }]),
    style: _normalizeStyle(_ctx.chipColor)
  }, [
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.text), 1),
    (_ctx.hasAction)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: "chip-icon material-icons no-select",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onSelect')))
        }, _toDisplayString(_ctx.iconKey || 'add_circle'), 1))
      : _createCommentVNode("", true)
  ], 6))
}