
import { defineComponent } from 'vue';
import KeywordService from '@/services/keyword.service';
import Searchbar from '@/components/Searchbar.vue';
import Logo from '@/components/Logo.vue';
import KeywordSuggestions from '@/components/KeywordSuggestions.vue';

/**
 * View which is responsible for displaying the landing page at '/'
 */
export default defineComponent({
    name: 'Search',
    components: {
        Searchbar,
        Logo,
        KeywordSuggestions,
    },
    data() {
        return {
            keywordService: new KeywordService(),
        };
    },
    created(): void {
        // when created the loading bar should be hidden as well as resetting the saved state to clear filters, ...
        this.$store.commit('HIDE_LOADING_BAR');
        this.$store.dispatch('resetSavedState');
    },
    computed: {
        /**
         * Returns the search terms from the state
         */
        searchTerms(): string[] {
            return this.$store.state.searchTerms;
        },
        /**
         * Returns the suggested terms from the state
         */
        suggestedTerms(): string[] {
            return this.$store.state.suggestedTerms;
        },
    },
    methods: {
        /**
         * Update the array from store on inserting keyword and the suggestion terms
         * $store is the global variable that have access to the all containers
         * @param event - represent the inserted keyword either from suggestion list or typed word
         */
        async onAddKeyword(event: { value: string }) {
            this.$store.commit('ADD_SEARCH_TERM', event.value);
            this.$store.commit('SHOW_LOADING_BAR');

            try {
                const newSuggestions = await this.keywordService.getSuggestions(this.searchTerms);
                this.$store.commit('ADD_SUGGESTIONS', newSuggestions);
            } catch (err) {
                console.log(err);
            } finally {
                this.$store.commit('HIDE_LOADING_BAR');
            }
        },
        /**
         * Update the search and the suggestion terms arrays  from store
         * @param event the remove keyword from the search input
         */
        async onRemoveKeyword(event: { index: number; value: string }) {
            this.$store.commit('REMOVE_SEARCH_TERM', event);
            this.$store.commit('SHOW_LOADING_BAR');

            const newSuggestions = await this.keywordService.getSuggestions(this.searchTerms);
            this.$store.commit('ADD_SUGGESTIONS', newSuggestions);

            this.$store.commit('HIDE_LOADING_BAR');
        },

        /**
         * Event handler which shows the loading screen and triggers the search by pushing the search terms to the url
         */
        onSearch() {
            this.$store.commit('SHOW_LOADING_SCREEN');
            this.$router.push({ path: 'search', query: { terms: this.searchTerms } });
        },
    },
});
