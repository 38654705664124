
import { defineComponent } from 'vue';

/**
 * Simple chip component.
 *
 * The text displayed can be modified using the text property
 * The action icon can be modified using google font icon keys.
 *
 * Example:
 * <Chip text="keyword" icon-key="cancel" is-suggestion="true"></Chip>
 *
 * Usually used icons:
 * cancel || add_circle
 */
export default defineComponent({
    name: 'Chip',
    props: {
        text: String,
        iconKey: String,
        isSuggestion: Boolean,
        hasShadow: {
            type: Boolean,
            default: true,
        },
        hasAction: {
            type: Boolean,
            default: true,
        },
        customColor: {
            type: String,
        },
    },
    emits: ['onSelect'],
    computed: {
        /**
         * Returns the chip color. It will attempt to use the custom color property. By default it will return
         * a black background color
         */
        chipColor(): string {
            let color;

            if (this.customColor) {
                color = this.customColor;
            } else if (this.isSuggestion) {
                color = '#5b9761';
            } else {
                color = '#000000';
            }

            return 'background: '.concat(color).concat(';');
        },
    },
});
