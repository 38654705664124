
import Chip from '@/components/Chip.vue';
import { defineComponent } from 'vue';

/**
 * Searchbar component which allows the user to input & change inputs
 */
export default defineComponent({
    name: 'Searchbar',
    components: {
        Chip,
    },
    props: {
        searchTerms: Array,
    },
    emits: {
        onAddKeyword: (event: { value: string }) => event,
        onRemoveKeyword: (event: { index: number; value: string }) => event,
        onSearch: (event: { searchTerms: string[] }) => event,
        inputFocused: (event: boolean) => event,
        inputNotFocused: (event: boolean) => event,
    },
    data() {
        return {
            currentKeyword: '',
            clicked: false,
        };
    },
    watch: {
        /**
         * Watcher which checks the current search input for a space bar input
         * @param val   The new search input
         */
        currentKeyword(val: string): void {
            if (!val || val.charAt(val.length - 1) !== ' ') {
                return;
            }

            this.addKeyword();
        },
    },
    methods: {
        /**
         * Adds a keyword to the keywords array and resets the v-model value of currentKeyword
         */
        addKeyword() {
            if (this.currentKeyword.length === 0) {
                return;
            }

            if (this.currentKeyword.trim().length === 0) {
                this.currentKeyword = '';
                return;
            }

            const newKeyword = this.currentKeyword.trim();
            // this.$props.enteredKeywords.push(newKeyword);
            this.$emit('onAddKeyword', { value: newKeyword });
            this.currentKeyword = '';

            this.setFocus();
        },

        /**
         * Removes an item from the enteredKeywords array specified by the index
         * @param index The index of the keyword to be removed
         */
        removeKeyword(index: number): string {
            const value = (this.$props.searchTerms || [])[index] as string;
            this.$emit('onRemoveKeyword', { index, value });
            return value;
        },

        /**
         * Attempts to set the focus back on the searchBar
         */
        setFocus(): void {
            (this.$refs.searchBar as HTMLInputElement)?.focus();
        },

        /**
         * Handler for the back-key
         */
        handleDeleteKey(): void {
            if ((this.searchTerms as string[]).length < 1 || (this.currentKeyword as string).length > 0) {
                return;
            }

            const keyword = this.removeKeyword((this.searchTerms as string[]).length - 1);
            setTimeout(() => (this.currentKeyword = keyword), 20);
        },

        /**
         * Handler for the enter key and search icon press
         */
        initializeSearch(): void {
            if (this.currentKeyword.trim().length > 0) {
                this.addKeyword();
                this.$emit('onSearch', { searchTerms: this.searchTerms as string[] | [] });
            }
            // if no search terms are specified we need to prevent the state change.
            if (!this.searchTerms || this.searchTerms.length === 0 || this.$route.path === '/search') {
                return;
            }
            this.$emit('onSearch', { searchTerms: this.searchTerms as string[] | [] });
        },
    },
});
