
import { defineComponent } from 'vue';

/**
 * This component contains the loading screen which can be shown by setting the
 * state variable 'showLoadingScreen' to true
 */
export default defineComponent({
    name: 'LoadingScreen',
    computed: {
        /**
         * Exposes the state variable showLoadingScreen
         */
        showLoadingScreen(): boolean {
            return this.$store.state.showLoadingScreen;
        },
    },
});
