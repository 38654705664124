import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "progress-bar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_LoadingScreen = _resolveComponent("LoadingScreen")!
  const _component_DialogMask = _resolveComponent("DialogMask")!
  const _component_Toasts = _resolveComponent("Toasts")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.showLoadingBar)
        ? (_openBlock(), _createBlock(_component_ProgressBar, {
            key: 0,
            mode: "indeterminate"
          }))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_LoadingScreen),
    _createVNode(_component_DialogMask),
    _createVNode(_component_Toasts),
    _createVNode(_component_router_view)
  ], 64))
}