import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7671d2f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Chip = _resolveComponent("Chip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.providedKeywords, (keyword) => {
      return (_openBlock(), _createBlock(_component_Chip, {
        "is-suggestion": true,
        "icon-key": "add",
        text: keyword,
        key: keyword,
        onOnSelect: ($event: any) => (_ctx.addKeyword(keyword))
      }, null, 8, ["text", "onOnSelect"]))
    }), 128))
  ]))
}