
import { defineComponent } from 'vue';
import LoadingScreen from '@/components/LoadingScreen.vue';
import Toasts from '@/components/Toasts.vue';
import DialogMask from '@/components/DialogMask.vue';

/**
 * App component which is used to add the router-outlet as well as general components to the app
 */
export default defineComponent({
    name: 'App',
    components: { LoadingScreen, Toasts, DialogMask },
    beforeCreate() {
        this.$store.dispatch('loadSavedState');
    },
    computed: {
        /**
         * Returns true if the loading bar should be shown
         */
        showLoadingBar(): boolean {
            return this.$store.state.showLoadingBar;
        },
    },
});
